import AuthorAvatar from "./AuthorAvatar"
import { Link } from "gatsby"
import moment from "moment"
import React from "react"
import slugVi from "../utils/slugVi"

export default ({ article }) => {
  if (!article.author?.username) return <div/>
  const usernameSlug = slugVi(article.author.username)
  return (
    <div style={{ display: "flex", marginBottom: 20 }}>
      <AuthorAvatar author={article.author}/>
      <Link to={`/contributor/${usernameSlug}`} style={{ boxShadow: "none" }}>
        {article.author.username}
      </Link>
      <small>
        <span role={"img"} aria-label={"img"}>&nbsp;✍︎︎&nbsp;</span>
        {moment(article.updated_at || article.created_at).format("DD-MM-YYYY")}
      </small>
    </div>
  )
}
